<template>
  <div class="relative flex lg:pt-8">
    <mq-layout mq="lg+">
      <nav class="relative w-full pb-32 sidemenu md:w-64">
        <div class="w-full">
          <div class="p-4 mx-auto text-center">
            <img class="w-full h-32 mb-12"
              src="//images.ctfassets.net/3dar4x4x74wk/5EAAt0PXAld0LTDEgEYPu3/9d34c7869602e51c2c0304101da3ef5f/eats-sweets-drinks-omg-lockup.svg"
              :alt="content.heading">
            <img
              :src="`//images.ctfassets.net/3dar4x4x74wk/6Oc5Ipo91Q29ApwmpppBQt/a8f845dd0f348b863c684be34b0f7d95/ME-family-kitchen-logo.png`"
              :alt="content.title">
          </div>
        </div>
        <div class="w-full text-center text-white uppercase" v-for="(category, index) in content.children"
          :key="category.index">
          <a href="#" @click.prevent="showMenu(index, category)"
            class="block w-full px-12 py-3 leading-none transition-all duration-200 ease-out font-kentp hover:bg-underbrush"
            :class="{ 'bg-underbrush': index == currentIndex }">
            <div v-html="category.fields.navName || category.fields.title"> </div>
          </a>
        </div>
      </nav>
    </mq-layout>
    <section id="menu-items" class="relative flex-1 w-full min-h-full bg-milk">
      <div class="w-full mb-4 menu-hero-container bg-seaworthy-green">
        <div v-if="currentCategory && currentCategory.fields"
          class="relative z-30 lg:rounded-tl-4xl lg:overflow-hidden animated fadeIn ">

          <div v-if="currentCategory.fields.callOutText"
            class="absolute bottom-0 right-0 z-10 px-3 py-1 mb-32 bg-underbrush font-kentps rounded-l-2xl text-milk">
            {{
                currentCategory.fields.callOutText
            }}</div>
          <img class="hidden w-full lg:block" v-if="catImage && catImage.fields"
            :src="`${catImage.fields.file.url}?w=2800&h=600&fit=fill&fm=webp`" :alt="currentChildCats.heading">
          <img class="w-full pb-10 lg:hidden md:pb-0" v-if="catImage && catImage.fields"
            :src="`${catImage.fields.file.url}?w=1300&h=900&fit=fill&fm=webp`" :alt="currentChildCats.heading">
          <mq-layout :mq="['sm', 'md']" class="flex items-center w-full">
            <mobile-food-menu @showmenu="showMobileMenu" :cat="currentCategory" :content="content" />
          </mq-layout>
        </div>
      </div>
      <div
        :class="`bg-milk w-full grid lg:grid-cols-2 grid-flow-row ${currentChildCats.gridPlacement ? currentChildCats.gridPlacement.split(':').pop() : 'place-items-end'} gap-4 py-8`">
        <div
          :class="`w-full px-4 lg:px-12 animated fadeIn ${currentChildCats.rowSpan ? `row-span-1 lg:row-span-${currentChildCats.rowSpan}` : 'row-span-1'} ${currentChildCats.colSpan ? `col-span-1 lg:col-span-${currentChildCats.colSpan}` : 'col-span-1'}`">
          <div class="w-full max-w-md">
            <h1
              class="mb-2 text-4xl uppercase font-kentp text-underbrush lg:text-5xl tracking-condensed leading-pressed"
              v-html="currentChildCats.heading">
            </h1>

            <p v-if="currentChildCats.subHeading" class="pb-4 text-sm leading-none font-kentp text-seaworthy-green">
              {{ currentChildCats.subHeading }}</p>

            <div v-if="currentChildCats.emphasizedText"
              :class="`font-kentp text-sm leading-none inline-block align-middle py-2 px-4 text-${currentChildCats.emphasizedTextColor}  rounded-2xl border-2 border-${currentChildCats.emphasizedTextColor}`">
              <span> {{ currentChildCats.emphasizedText }}</span>
            </div>

          </div>
          <div v-if="currentChildCats.leftColOptions" class="flex flex-wrap w-full">
            <div class="w-full p-4 lg:pl-0 lg:w-1/2" v-if="currentChildCats.leftColOptions">
              <div class="text-lg cat-text font-kentps lg:text-xl" v-if="currentChildCats.leftColOptions"
                v-html="$md.render(currentChildCats.leftColOptions)">

              </div>
            </div>
            <div class="w-full p-4 lg:pl-0 lg:w-1/2">
              <div class="text-lg cat-text font-kentps lg:text-xl" v-if="currentChildCats.rightColOptions"
                v-html="$md.render(currentChildCats.rightColOptions)">

              </div>
            </div>
          </div>
        </div>

        <div v-for="item in currentChildCats.menuItems" :key="item.sys.id"
          :class="`w-full animated fadeIn relative ${item.fields.bundle ? 'lg:col-span-2' : ''} ${item.fields.featured ? `${item.fields.featureAlignment === 'right' ? 'pl-4 lg:pl-8' : 'pr-4 lg:pr-8'} ${item.fields.rowSpan ? `row-span-${item.fields.rowSpan}` : 'row-span-3'}  ` : `${item.fields.rowSpan ? `row-span-${item.fields.rowSpan}` : 'row-span-1'}`}`">
          <div v-if="item.fields.featured" class="w-full">
            <div
              :class="`bg-seaworthy-green relative w-full transform overflow-hidden featured-item ${item.fields.featureAlignment === 'right' ? 'rounded-l-3xl' : 'rounded-r-3xl'} ${item.fields.bundle ? 'w-full xl:w-1/2' : ''}`">

              <div :class="`px-4 py-3 lg:py-4 ${item.fields.featureAlignment === 'left' ? 'lg:px-12' : 'lg:px-8'}`">
                <h3 class="pb-1 mt-1 text-xl leading-none text-white uppercase font-kentps lg:text-2xl ">{{
                    item.fields.title
                }}
                </h3>
                <div v-if="item.fields.description" v-html="item.fields.description"
                  class="text-sm leading-none font-kentp lg:mr-12 menu-item-desc">
                </div>
              </div>
              <img v-if="item.fields.image" :src="item.fields.image.fields.file.url"
                :alt="item.fields.image.fields.title">
            </div>


            <div v-if="item.fields.leftColOptions && item.fields.bundle" class="flex flex-wrap w-full p-4 lg:px-12">

              <div class="w-full px-4 pt-4 pb-8 lg:pr-16 lg:pl-0 lg:w-1/2" v-if="item.fields.leftColOptions">
                <div class="text-lg font-kentps bundle-item-opts" v-if="item.fields.leftColOptions"
                  v-html="$md.render(item.fields.leftColOptions)">

                </div>
              </div>
              <div class="w-full px-4 lg:p-4 lg:pl-16 lg:w-1/2">
                <div class="text-lg font-kentps bundle-item-opts" v-if="item.fields.rightColOptions"
                  v-html="$md.render(item.fields.rightColOptions)">

                </div>
              </div>

            </div>

          </div>

          <div v-else-if="item.fields.customHeadBg"
            class="relative flex flex-wrap items-center flex-1 w-full px-4 lg:px-12">
            <div class="relative w-full">
              <h3
                class="pt-4 text-2xl leading-none text-center uppercase font-kentps lg:pt-2 xl:pt-4 text-underbrush xl:text-4xl ">
                {{
                    item.fields.title
                }}
              </h3>
              <img class="absolute top-0 left-0 w-full px-4 lg:px-0"
                v-if="item.fields.customHeadBg.fields.file && item.fields.customHeadBg.fields.file.url"
                :src="item.fields.customHeadBg.fields.file.url" alt="bg">
            </div>
            <div class="w-full p-8 text-white bg-seaworthy-green rounded-2xl">
              <div v-if="item.fields.description" v-html="item.fields.description"
                class="text-center menu-item-desc-hightlight font-kentps">
              </div>
              <h3 v-if="item.fields.optionsHeading"
                class="pt-4 text-xl leading-none text-center uppercase font-kentps lg:pt-2 xl:pt-4 ">
                {{
                    item.fields.optionsHeading
                }}
              </h3>
              <div v-if="item.fields.leftColOptions" class="flex flex-wrap w-full">

                <div class="w-full px-4 pt-4 lg:p-4 lg:pl-0 lg:w-1/2" v-if="item.fields.leftColOptions">
                  <div class="text-xs text-center font-kentp" v-if="item.fields.leftColOptions"
                    v-html="$md.render(item.fields.leftColOptions)">

                  </div>
                </div>
                <div class="w-full px-4 lg:p-4 lg:pl-0 lg:w-1/2">
                  <div class="text-xs text-center font-kentp" v-if="item.fields.rightColOptions"
                    v-html="$md.render(item.fields.rightColOptions)">

                  </div>
                </div>
              </div>
            </div>

          </div>
          <div v-else :class="`flex flex-wrap items-center flex-1 w-full px-4 lg:px-12 relative`">

            <div v-if="item.fields.highlightText"
              :class="`font-kentp text-sm leading-none inline-block mb-4 align-middle py-2 px-4 text-underbrush  rounded-2xl border-2 border-underbrush`">
              <span> {{ item.fields.highlightText }}</span>
            </div>
            <div :class="`w-full max-w-xl ${item.fields.showEmphasis ? 'flex-1' : ''}`">
              <h3 v-if="item.fields.title" class="text-xl leading-none uppercase font-kentps lg:text-2xl"
                v-html="item.fields.title"> </h3>
              <div v-if="item.fields.description" class="leading-none font-kentp lg:mr-12"
                v-html="item.fields.description"></div>

              <div v-if="item.fields.extraDescription" v-html="item.fields.extraDescription"
                class="pt-2 text-sm leading-none font-kentp lg:mr-12 text-underbrush">
              </div>
              <h4 v-if="item.fields.optionsHeading" class="mt-4 mb-4 text-xl leading-none uppercase font-kentps">{{
                  item.fields.optionsHeading
              }}</h4>
              <div v-if="item.fields.leftColOptions" class="flex flex-wrap w-full options-text">
                <div class="w-full uppercase lg:w-1/2 font-kentp" v-html="$md.render(item.fields.leftColOptions)"></div>
                <div v-if="item.fields.rightColOptions" class="w-full uppercase lg:w-1/2 font-kentp"
                  v-html="$md.render(item.fields.rightColOptions)"></div>
              </div>
            </div>
            <img class="w-16 mr-4 cursor-pointer " v-if="item.fields.showEmphasis"
              @mouseover="$store.commit('setFoodModal', { toggled: true, content: item })"
              src="//images.ctfassets.net/3dar4x4x74wk/4RQZy8QNaLHPpP4GF5jBHx/1643f43bcd3e692939791dc9bca41a09/checkit-out-bubble.svg"
              alt="check it out">
          </div>
        </div>
      </div>
      <div class="block w-full px-4 py-8 lg:px-12 lg:flex lg:items-center lg:justify-between">
        <a class="inline-block w-full px-4 py-2 leading-none text-center transition-all duration-200 ease-out font-kentp bg-seaworthy-green text-milk lg:-ml-4 rounded-xl hover:bg-underbrush lg:mt-0 lg:w-auto"
          href="//assets.ctfassets.net/3dar4x4x74wk/7e9lkEYpryVs9TsVTBEdxX/f86374bdeef36e73e163df0da38487e3/022123_ME_FK_info.pdf"
          target="_blank">
          Nutritional Information
        </a>
        <a class="inline-block w-full px-4 py-2 mt-6 leading-none text-center transition-all duration-200 ease-out lg:mx-6 font-kentp bg-seaworthy-green text-milk rounded-xl hover:bg-underbrush lg:mt-0 lg:w-auto"
          href="https://assets.ctfassets.net/3dar4x4x74wk/7QRwSDg102GhgKyZw2Ewo/88489a764b512a0c45295869e02e2f2d/Allergen_Menu.pdf"
          target="_blank">
          Allergens
        </a>
        <button @click="$store.commit('setLocationFinder', true)" class="w-full h-auto px-4 py-2 mt-6 leading-none tracking-normal transition-all duration-200 ease-out font-kentp bg-seaworthy-green text-milk rounded-xl hover:bg-underbrush lg:mt-0 lg:w-auto">Select another Location</button>
      </div>
    </section>

  </div>
</template>

<script>
import MenuItem from './MenuItem'
import MenuSectionMedia from './MenuSectionMedia'
import MobileFoodMenu from './MobileFoodMenu'

export default {
  components: {
    MenuItem,
    MenuSectionMedia,
    MobileFoodMenu
  },
  props: [
    'content',
    'legal',
  ],
  data() {
    return {
      currentIndex: 0,
      currentCategory: {}
    }
  },
  methods: {
    showMobileMenu(params) {
      this.showMenu(params.catindex, params.category)
    },
    showMenu(index, category) {
      this.currentIndex = index
      this.currentCategory = category
    }
  },
  mounted() {
    this.currentCategory = this.content.children[0]
  },
  computed: {
    childCats() {
      if (!this.content.children && !this.content.children.length) return ''
      return this.content.children.map(c => {
        return c.fields.menuSections
      })
    },
    currentChildCats() {
      return this.childCats[this.currentIndex][0].fields
    },
    catImage() {
      return this.currentCategory.fields.categoryImage
    }
  },
}

</script>
<style lang="postcss">
.purge-a {
  @apply leading-loose leading-snug -translate-x-4 translate-x-4 -translate-x-8 translate-x-8 row-span-1 row-span-2 row-span-3 row-span-4 row-span-5 border-seaworthy-green border-underbrush col-span-1 col-span-2 col-span-3 col-span-4 col-span-5 text-milk;
}

.place-items-end {
  place-items: flex-end;
}

.place-items-center {
  place-items: center;
}

.options-text li {
  @apply leading-none mb-2;
}

.cat-text h3 {
  @apply text-underbrush inline-block text-base font-kentp py-1 mb-2 px-4 border-2 border-underbrush rounded-2xl -ml-4;
}

.bundle-item-opts h2 {
  @apply text-underbrush inline-block text-base font-kentp py-1 mb-4 px-4 border-2 border-underbrush rounded-2xl -ml-4;
}

.bundle-item-opts li {
  @apply leading-none mb-2;
}

.menu-item-desc {
  @apply text-underbrush;
}

.menu-item-desc p {
  @apply mb-1;

  &:last-child {
    @apply mb-0;
  }
}

.menu-item-desc-hightlight h2 {
  @apply text-2xl leading-none;
}

.menu-item-desc-hightlight h4 {
  @apply text-xl mb-2 leading-none;
}

.menu-item-desc-hightlight h5 {
  @apply text-base font-kentp leading-none;
}

.menu-item-desc-hightlight p {
  @apply font-kentp mb-0 pb-0 text-xs;
}

.menu-item-desc p {
  @apply text-underbrush pb-1;
}

.menu-item.featured-right {
  @apply translate-x-4 rounded-l-3xl;

  @screen lg {
    @apply translate-x-8;
  }

}

.menu-item.featured-left {
  @apply -translate-x-4 rounded-r-3xl;

  @screen lg {
    @apply -translate-x-8;
  }
}
</style>
